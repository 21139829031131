<template>
  <div class="globalPay">
    <Modal v-model="showPayStatus" confirm-text="已完成" cancel-text="未支付" title="提示" :z-index="110" @confirm="payresult('success')" @cancel="payresult('cancel')">
      是否已经完成了支付？
    </Modal>
    <div v-html="alipayForm"></div>
    <Mpopup v-model="showPay" title="请选择支付方式" :show-close="true" height="30vh">
      <div style="padding-left: 1rem;">
        <div class="radio-wrap" @click="pay('WEIXIN')">
          <div v-if="!isAlipay" class="radio">
            <span class="wechatpay"></span>微信支付<label></label>
          </div>
        </div>
        <div v-if="!$store.state.globalData.isWechat" class="radio" @click="pay('ZHIFUBAO')">
          <span class="alipay"></span>支付宝支付<label></label>
        </div>
      </div>
    </Mpopup>
    <Mpopup v-model="showCoupon" title="优惠券">
      <div class="coupon-wrap">
        <div class="desc">
          优惠券使用说明：下单时可预览可用优惠券与优惠后的价格，到支付运费时根据实际价格可重新选择并使用满足条件的优惠券
        </div>
        <div scroll-y>
          <div v-for="(item) in couponList" :key="item.id" class="item" :class="{active: dataSelectId == item.id}" @click="setCoupon(item)">
            <div class="val">
              <span class="mark text-one">{{ item.sub_title }}</span>
            </div>
            <div class="text">
              {{ item.title }}
            </div>
            <div class="select"></div>
          </div>
        </div>
      </div>
    </Mpopup>
    <div v-if="done" class="list">
      <div class="list-waitpay">
        <div>
          <span>待付费用：</span><span>{{ detail.paystatus === 'WAITPAY' ? (totalprice || '') : 0 }}</span><span>元</span>
        </div>
        <div>
          <span>已支付：</span><span>{{ payedprice ? payedprice/100 : 0}}</span><span>元</span>
        </div>
      </div>
      <div v-if="JSON.stringify(detail) !== '{}' && detail.paystatus !== 'WAITPAY'" class="paysuccess">
        <div class="paysuccess-img">
          <img src="https://cdn.kuaidi100.com/images/rebuild/pay_success.png">
        </div>
        <div class="paysuccess-status">
          支付成功
        </div>
        <div class="paysuccess-tips1">
          感谢使用快递100国际快递
        </div>
        <div class="paysuccess-tips2">
          此链接会在7天内过期，请在您寄件的渠道查看订单
        </div>
      </div>
      <template v-if="detail.paystatus === 'WAITPAY'">
        <div v-for="(item, index) in priceinfo" class="list-detail" :key="index">
          <template v-if="item.type !== 'cost'">
            <span>{{item.label}}</span>
            <span>{{item.desc}}</span>
          </template>
        </div>
        <div v-if="this.couponList.length > 0" class="list-coupon" @click="isShowCoupon">
          <div class="list-coupon-top">
            <span>优惠券抵扣</span>
            <span class="orange">{{ dataSelectId ? couponText : `有${couponList.length}张可用的优惠券 >` }}</span>
          </div>
          <div v-if="couponList.length > 0" class="list-coupon-bottom">
            点击查看全部{{ couponList.length }}张可用的优惠券
          </div>
        </div>
        <div v-if="totalprice" class="list-total">
          <span>总费用</span>
          <span>{{ totalprice }}元</span>
        </div>
      </template>
    </div>
    <div class="order-box">
      <div class="order-card">
        <div class="order-info">
          <div class="addr">
            {{ detail.sendcity }}
          </div>
          <div class="name">
            {{ detail.sendName }}
          </div>
        </div>
        <div class="order-status">
          <div class="status0"></div>
          <div class="status-icon" />
        </div>
        <div class="order-info">
          <div class="addr">
            {{ detail.recprovince }}
          </div>
          <div class="name">
            {{ detail.recName }}
          </div>
        </div>
      </div>
      <div v-if="orderDetailShow" class="detail-item">
        <div class="item">
          <div class="item-label">
            寄件信息：
          </div>
          <div class="item-content">
            <div>{{ detail.sendName }}&nbsp;&nbsp;{{ detail.sendmobile ? detail.sendmobile : detail.sendtel }}</div>
            <div class="contact-addr">
              {{ (detail.sendxzq || '').replace(/#/g, '') }} {{ detail.sendaddr }}
            </div>
          </div>
        </div>
        <div class="item">
          <div class="item-label">
            收件信息：
          </div>
          <div class="item-content">
            <div>{{ detail.recName }} {{ detail.recmobile }}</div>
            <div class="contact-addr">
              <!-- {{ detail.recprovince }} {{ detail.recaddr }} -->
              {{ detail.recaddr || ''}} {{ detail.reccounty || ''}} {{ detail.reccity || ''}} {{ detail.recprovince || ''}}
            </div>
          </div>
        </div>
        <div class="item">
          <div class="item-label">
            付款方式：
          </div>
          <div class="item-content">
            {{ detail.payway }}
          </div>
        </div>
        <div class="item">
          <div class="item-label">
            物品信息：
          </div>
          <div class="item-content">
            {{ detail.cargo }}{{ detail.cargoInclude | formatSpecialCaro}}，{{ detail.weight }}公斤
          </div>
        </div>
        <div v-if="detail.comment" class="item">
          <div class="item-label">
            留言：
          </div>
          <div class="item-content">
            {{ detail.comment }}
          </div>
        </div>
        <div v-if="detail.comment" class="item">
          <div class="item-label">
            订单号：
          </div>
          <div class="item-content" @click="copyExpid">
            {{ detail.expid }}<span style="color: #317ee7;padding-left: 10px;">复制</span>
          </div>
          <div class="copytxt" style="position: fixed;top:-9999px;left: -9999px;">
            {{ detail.expid }}
          </div>
        </div>
        <div class="item">
          <div class="item-label">
            下单时间：
          </div>
          <div class="item-content">
            {{ detail.created }}
          </div>
        </div>
        <div class="item">
          <div class="item-label">
            下单来源：
          </div>
          <div class="item-content">
            {{ detail.source }}
          </div>
        </div>
      </div>
      <div v-if="orderDetailShow" class="order-expand" @click="orderDetailShow = !orderDetailShow">
        <div>点击收起订单详情</div>
        <img src="https://cdn.kuaidi100.com/images/rebuild/image/collapse.png">
      </div>
      <div v-if="!orderDetailShow" class="order-expand" @click="orderDetailShow = !orderDetailShow">
        <div>点击查看订单详情</div>
        <img src="https://cdn.kuaidi100.com/images/rebuild/image/expand.png">
      </div>
    </div>
    <div class="tips">
      本页面由快递100提供，请勿将链接给其他人，以免泄露信息 如有疑问可拨打电话<a href="tel:4000000387">4000000387</a>
    </div>
    <div v-if="detail.paystatus === 'WAITPAY'" class="pay" @click="toPay">
      <button>确认并支付</button>
    </div>
  </div>
</template>

<script>
import Modal from "components/Modal/Modal"
import util from "lib/util"
import { mapActions } from 'vuex'
import Mpopup from "components/Mpopup/Mpopup"
export default {
  name: 'GlobalPay',
  components: {
    Modal,
    Mpopup
  },
  data() {
    return {
      aa: '',
      showPay: false,
      orderDetailShow: false,
      priceinfo: {},
      detail: {},
      mktInfo: {},
      couponList: [],
      showCoupon: false,
      dataSelectId: '',
      couponItem: {},
      alipayForm: '',
      token: '',
      done: false,
      showPayStatus: false,
      ua: '',
      isAlipay: false,
      originalprice: 0,
      payedprice: 0,
      current_type: ''
    }
  },
  computed: {
    totalprice () {
      return (+this.originalprice - (this.couponItem.top_limit || 0) * 100)/100
    },
    couponText () {
      const coupon = this.couponItem
      const maxPrice = Math.max.apply(null,this.couponList.map(item => item.top_limit))
      const maxId = (this.couponList.find(item => item.top_limit === maxPrice) || {}).id
      if (coupon.id === maxId) {
        return '已选最大优惠-' + coupon.top_limit + '元 >'
      } else {
        return '已优惠抵扣' + coupon.top_limit + '元 >'
      }
    },
  },
  created () { // onLoad
    this.ua = navigator.userAgent.toLowerCase()
    this.isAlipay = this.ua.indexOf('aliapp') > -1
    this.orderinfo4pay()
    if(this.$store.state.globalData.isWechat) {
      util.loadScript("//res.wx.qq.com/open/js/jweixin-1.0.0.js", function() {})
    }
    if(sessionStorage.getItem('showPayStatus') == 1) {
      this.showPayStatus = true
    }
  },
  beforeRouteEnter(to, from, next) {
    next(vm => {
      if(from.name == 'login' && vm.$store.state.globalData.token) {
        vm.loginCallback()
      }
    })
  },
  methods: {
    orderinfo4pay() { // 订单详情
      const url = '/apicenter/order.do?method=orderinfo4payV2'
      const params = {
        data: {
          expid4pay: this.$route.query.expid4pay
        },
        handleFail: false
      }
      this.$http.post(url, params).then((res) => {
        this.priceinfo = res.priceinfo
        this.detail = res.detail
        this.mktInfo = res.mktInfo
        this.token = res.token
        this.originalprice = res.originalprice
        this.payedprice = res.payedprice || 0
        this.current_type = res.current_type
        if(this.current_type === 0) {
          this.intCoupon()
        }
      }).finally(()=> {
        this.done = true
      })
    },
    copyExpid() { // 复制
      const range = document.createRange();
      range.selectNode(document.querySelector(".copytxt"));
      const selection = window.getSelection();
      if(selection.rangeCount > 0) selection.removeAllRanges();
      selection.addRange(range);
      document.execCommand('Copy');
      this.$toast('复制成功')
    },
    payresult (type) { // 是否完成支付
      const url = '/apicenter/order.do?method=payresult'
      const params = {
        data: {
          expid: this.detail.expid,
          token: this.token,
          type: type
        }
      }
      this.$http.post(url, params).then(()=> {
        
      }).finally(()=> {
        sessionStorage.setItem('showPayStatus', '')
        this.orderinfo4pay()
        this.showPayStatus = false
      })
    },
    callpay () {
      var f = this.wxPay
      if (typeof WeixinJSBridge == "undefined") {
        if (document.addEventListener) {
          document.addEventListener('WeixinJSBridgeReady', f, false)
        } else if (document.attachEvent) {
          document.attachEvent('WeixinJSBridgeReady', f)
          document.attachEvent('onWeixinJSBridgeReady', f)
        }
      } else {
        f()
      }
    },
    wxPay () {
      WeixinJSBridge.invoke(
        'getBrandWCPayRequest',
        this.jsApiParameters,
        (res) => {
          if (res.err_msg == "get_brand_wcpay_request:ok") {
            // this.$$router.back(-1)
            this.orderinfo4pay()
          } else if (res.err_msg == "get_brand_wcpay_request:fail") {
            this.$toast("支付失败，请稍后再试")
          } else if (res.err_msg == "get_brand_wcpay_request:cancel") {
            this.$toast("支付失败，请稍后再试")
          }
        }
      );
    },
    checkPay () {
      var count = 0
      this.tCheckPay = setInterval(() => {
        count++
        if(count > 60) {
          clearInterval(this.tCheckPay)
        }
        this.orderinfo4pay()
      }, 2000)
    },
    pay (type) {
      var backPath = process.env.NODE_ENV != 'development' ? "/mexpress/" : "/"
      var backUrl = `${backPath}order/global/pay?expid4pay=${this.$route.query.expid4pay}`
      const loading = this.$loading('加载中')
      this.$http.post("/apicenter/order.do?method=thirdPay", {
        data: {
          expid: this.detail.expid,
          tradetype: type === 'WEIXIN' && typeof (wx) != "undefined" ? "JSAPI" : "MWEB",
          payway: type,
          quitUrl: encodeURIComponent(backUrl),
          couponid: this.couponItem.id || '',
          sign: this.detail.sign,
          token: this.token,
          current_type: this.current_type
        }
      }).then(result => {
        if (type === 'WEIXIN') {
          if (typeof (wx) != "undefined") { //微信公众号支付
            this.jsApiParameters = JSON.parse(result.weixinpayReqStr || result.wxpayreq)
            this.callpay()
          } else { // 微信支付
            let obj = JSON.parse(result.wxpayreq)
            if (obj.mweb_url) {
              this.checkPay()
              location.href = obj.mweb_url
            } else {
              this.$toast('订单错误，请刷新重试')
            }
          }
        } else {
          this.alipayForm = result.zfbappreq // result.alipayReqStr
          this.$nextTick(function() {
            document.forms.punchout_form.submit()
          })
        }
        sessionStorage.setItem('showPayStatus', 1)
        this.showPay = false
      }).finally(()=> {
        loading.hide()
      })
    },
    toPay () {
      this.showPay = true
    },
    isShowCoupon () {
      if (this.couponList.length > 0) {
        this.showCoupon = true
      }
    },
    setCoupon(item) { // 选择优惠券
      if(item.cardType === 'CARD_TYPE_FULL_CUT' && (this.originalprice < item.least_cost)) {
        this.$toast('您订单金额不符合使用条件')
        return
      }
      if(this.dataSelectId == item.id) { // 取消选中
        this.dataSelectId = ''
        this.couponItem = {}
      } else {
        this.dataSelectId = item.id
        this.couponItem = item
      }
      this.showCoupon = false
    },
    intCoupon() { // 优惠券
      const url = '/apicenter/card.do?method=intCoupon'
      const params = {
        data: {
          sign: this.detail.sign,
          token: this.token
        },
        handleFail: false
      }
      this.$http.get(url, params).then((res) => {
        this.couponList = res.data || []
        if(this.$route.query.couponid) {
          let id = this.$route.query.couponid
          for(let i = 0; i < this.couponList.length; i++) {
            let item = this.couponList[i]
            if(id == item.id) {
              this.dataSelectId = item.id
              this.couponItem = item
              break
            }
          }
        } else if(this.couponList.length > 0) {
          this.autoChoose()
        }
      })
    },
    autoChoose () { // 自动选择优惠券
      for(let i = 0; i < this.couponList.length; i++) {
        let item = this.couponList[i]
        if (item.cardType === 'CARD_TYPE_FULL_CUT' && (this.originalprice < item.least_cost)) {
          continue
        } else {
          this.dataSelectId = item.id
          this.couponItem = item
          break
        }
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.paysuccess {
  text-align: center;
  &-img {
    img {
      width: 4.125rem;
      height: 4.125rem;
    }
  }
  &-status {
    margin-top: 0.6875rem;
    font-size: 1.25rem;
    color: #333;
  }
  &-tips1 {
    margin-top: 1.125rem;
    font-size: 1.0625rem;
    color: #878787;
  }
  &-tips2 {
    margin: 0.5rem 0 2rem;
    font-size: 0.8125rem;
    color: #ff7f02;
  }
}
.radio {
  position: relative;
  padding: 1em 0;
  line-height: 1rem;
  color: #878787;
}
.radio label {
  float: right;
  vertical-align: -3px;
  width: .5rem;
  height: .5rem;
  padding: .25rem;
  border-radius: 50%;
  margin-right: 1em;
  border: 1px solid;
  background-clip: content-box;
  color: #bebebe;
}
.radio input {
  position: absolute;
  width: 100%;
  top: 0;
  bottom: 0;
  height: 100%;
  border-radius: 0;
}
.radio input:checked ~ label {
  color: #317ee7;
  background-color: #317ee7;
}
.wechatpay,.alipay {
  display: inline-block;
  width: 22px;
  height: 22px;
  background-size: 22px auto;
  background-repeat: no-repeat;
  vertical-align: -6px;
  margin-right: .625rem;
}
.wechatpay {
  background-image: url(https://cdn.kuaidi100.com/images/m/dispatch/wechatpay.png);
}
.alipay {
  background-image: url(https://cdn.kuaidi100.com/images/m/dispatch/alipay.png)
}
.coupon-wrap {
  padding: 0 0.625rem;
}
.coupon-wrap .desc {
  font-size: 0.8125rem;
  color: #888888;
  line-height: 1.15625rem;
  padding-bottom: 0.75rem;
}
.coupon-wrap div {
  margin-bottom: 1rem;
  max-height: 15.625rem;
  overflow: auto;
}
.coupon-wrap .item {
  display: flex;
  align-items: center;
  margin-bottom: 1rem;
  padding: 0 0.75rem 0.5rem;
  background: url(https://cdn.kuaidi100.com/images/m/dispatch/coupon-border.png) 0 100% repeat-x,url(https://cdn.kuaidi100.com/images/m/dispatch/coupon-bg.png) 98% center no-repeat;
  background-size: 0.5625rem,auto 70%;
  border-top: 0.3125rem solid;
  border-left: 1px solid #9cbdfe;
  border-right: 1px solid #9cbdfe;
  border-radius: 0.25rem 0.25rem 1px 1px;
  color: #317ee7;
  font-size: 1rem;
}
.coupon-wrap .val {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 5.46875rem;
  line-height: 4.59375rem;
  border-right: 1px dashed;
}
.coupon-wrap .mark{
  font-size: 1.5625rem;
  white-space: nowrap;
}
.coupon-wrap .text {
  flex: 1;
  padding-left: 1.125rem;
}
.coupon-wrap .select {
  position: relative;
  width: 1rem;
  height: 1rem;
  margin-right: 0.625rem;
  border: 1px solid #eee;
  border-radius: 50%;
  background-color: #FFF;
}
.coupon-wrap .active .select {
  background: url(https://cdn.kuaidi100.com/images/m/dispatch/select.png) #317ee7;
  background-size: 100%;
  border-color: #317ee7;
}
.coupon-btn {
  background: #ff7f02;
  border-radius: 0.25rem;
  color: #fff;
  text-align: center;
  line-height: 3rem;
}
.order-box {
  position: relative;
  background-color: #fff;
  margin: 0 0.625rem;
  z-index: 70;
  margin-top: 0.625rem;
  border-radius: 0.25rem;
  margin-bottom: 1rem;
  padding-top: 0.9375rem;
}
.br8{
  border-top-left-radius: 0.25rem;
  border-top-right-radius: 0.25rem;
}
.order-card {
  min-height: 5.15625rem;
  border-radius: 0.125rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 0 0.625rem;
}

.order-info {
  text-align: center;
  width: 40%;
}

.order-info .addr {
  font-size: 1.0625rem;
  color: #333;
}

.order-info .name {
  word-break: break-all;
  padding-left: 1.875rem;
  font-size: 0.875rem;
  color: #888;
  margin-top: 0.6875rem;
}

.order-status {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 20%;
}

.order-status .status {
  font-size: 0.875rem;
  color: #888;
  display: flex;
  align-items: baseline;
}
.order-status .status0{
  font-size: 0.875rem;
  color: #ff7f02;
  display: flex;
  align-items: baseline;
}
.order-status .status-icon {
  position: relative;
  background: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACIAAAAfCAYAAAH1pC8nAAAAAXNSR0IArs4c6QAAA/xJREFUWAmtVk1oE0EUdrdb21iNXqwIjQhSaL2oGLQNJSYVRKg/FQQRPagXexBBL8WD4KGHXjx4EEFE8CAIYquCoAiNTZsiEumpreJF8aAiWGltk5im6/e2eduXySTdlQ4k8733vvfNm9nZ2VmzptiGh4cPMTYTicQpMgqFwit2GgSGhoZs12EYPxmXBBxnMpncx1GTwMLCwiWkXyVsSB1yOA2jTjMu6Ymty3BLMgzjmW3bm5EVoUzYT9DZjY2NZwzIHobRFI/H7wH3g9gLwizsIJHLGkh5DFcoC0gHSF+lTdiZJzsxTBNU7rPtEDSzOC8JvDDPUSAE7OMUBH6J7g9+cxY50I4huITwD0wzXGpyCMLSJobZ2dnpLFyRT/LLUkTgAPdYqBJfSTaTqJdDqaNIHuESRSU4ybYUZJ/szZGRkV3FSX6WAczprrSrCbm7SCb4xRb2Qz0/Ezl3+E/C/1gKhkKh+ubm5pz0EaY1WY/Sb0uBIqmn2DsdOCGdAAU9PZ2ampoDaEkp6hnjqAh5JuuIWJMknkhKF1N92ulAoAWLOkVkrMUL7NAjaqK0tZutrq7uC5Mg1sW4Uq8ViUQiGZmAyvqlrWKTdiJIg2pA2qimV9oqdioBqXtsbCygBqWNgc5KW2I6F6chsgnOHBaRjhhqcNndS3D5H3HnQC16bNiJWCx2h0QGkHBimeoPmaZ5gaaz1V9aKRsFvKaDcT+5Udoj7IfTTFFffcS/I64d0ISSs+GkAAspfatiu6aJEfrwgm1zPRoATh6D/NaEHJeF4PVKQfbX1tbuYazrtTs2lUrtkOSOjo4JaatYK7K4uPiLiZhKnLHvnnZwOp2u9ZKoPQoqJZLozMwMfaO3CM48qt2Ltf0gfL6hdkkqqWQyGTotZRFEXYffJC5JO8n43+ZrRWgQ8c6rY/61LGt7NBr9pga82PT5s7C0R/ELNDQ0PA2Hw/MrJL5H/KCGsxY3xanR0dEQduasJl7VRW9wF3bnAK6uD/H851BY2WkmFVAwFVKpbczn8xNeN6gUMYPB4BvpQGGDKKZP+iReoRA6l0OYUFrmeMHOHsHA4xDY7SVhtTmYWBrf2ii/NR9XewCveliAcC6Xu+jcoWHE1ERUKg9keSu2wN+g8jU2nYp56MhcByOfvprz6N7h43ADt69P9NbQPcY9G0CYDgQCrW1tbT804nTBvgz/LV2MfdC4hgOu6i2EudzTq9uOQthO4tseg891cED0YYHLIHJv+i2CRHwfaFgR+nppT1EU8QBFnCNhv81XIfQRy2az2gMPRax4Pa1WHL811ThuDIdVi2sIgCLeYiWq3pEFXQt9FYLdPY5Be6DkrApwFr8rKKJdq+7D+Q8Ja51sAS9ceAAAAABJRU5ErkJggg==') center no-repeat;
  background-size: 1.0625rem 0.9375rem;
  width: 1.0625rem;
  height: 1.0625rem;
}

.order-status .status-icon::after {
  content: '';
  position: absolute;
  width: 3rem;
  height: 1px;
  background-color: #efeff4;
  left: 1.375rem;
  top: 0.5rem;
}

.order-status .status-icon::before {
  content: '';
  position: absolute;
  width: 3rem;
  height: 1px;
  background-color: #efeff4;
  right: 1.375rem;
  top: 0.5rem;
}

.order-expand {
  display: flex;
  font-size: 0.8125rem;
  color: #888;
  align-items: center;
  justify-content: center;
  padding-bottom: 0.9375rem;
  border-bottom-left-radius: 0.25rem;
  border-bottom-right-radius: 0.25rem;

}
.order-expand img {
  width: 0.8125rem;
  height: 0.8125rem;
  margin-left: 0.3125rem;
}
.detail-item {
  background: #fff;
  border-top: 1px solid #eee;
  margin: 0 0.625rem;
}

.detail-item .item {
  position: relative;
  display: flex;
  flex-direction: row;
  margin-top: 0.625rem;
}
.detail-item .item:first-child{
  margin-top: 1.0625rem
}
.detail-item .item:last-child {
  padding-bottom: 0.625rem;
}

.detail-item .item-label {
  font-size: 0.875rem;
  margin-left: 1.1875rem;
  color: #888;
  width: 4.6875rem;
}

.detail-item .item .item-content {
  font-size: 0.875rem;
  line-height: 1.25rem;
  max-width: 15.625rem;
  flex: 1;
}
.item-content >div{
  word-break: break-all;
}

.detail-item .item .item-copy {
  font-size: 0.875rem;
  color: #3581eb;
  margin-left: 0.625rem;
}
.contact-addr{
  color: #888;
}




.orange {
  color: #ff7f02;
}
.globalPay {
  .list {
    padding: 0.8rem 1.6rem;
    margin: 0.525rem 0.625rem;
    background: #fff;
    border-radius: 0.25rem;
    &-waitpay {
      font-size: 1rem;
      color: #333;
      display: flex;
      justify-content: space-between;
      padding-bottom: 2.2rem;
    }
    &-detail {
      font-size: 0.9375rem;
      color: #878787;
      display: flex;
      justify-content: space-between;
      padding: 0.24rem 0;
      span:last-child {
        color: #333;
      }
    }
    &-coupon {
      font-size: 0.9375rem;
      color: #878787;
      padding: 0.24rem 0;
      &-top {
        width: 100%;
        display: flex;
        justify-content: space-between;
      }
      &-bottom {
        text-align: right;
        font-size: 0.8rem;
      }
    }
    &-total {
      display: flex;
      justify-content: space-between;
      padding-top: 2.5rem;
      color: #ff7f02;
      font-weight: bold;
    }
  }
  .tips {
    font-size: 0.8125rem;
    color: #888888;
    padding: 0 0.625rem;
    text-align: center;
    margin-bottom: 100px;
  }
  .pay {
    height: 4rem;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    position: fixed;
    bottom: 0;
    left: 0;
    background: #efeff4;
    z-index: 70;
    button {
      background: #ff7f02;
      font-size: 1.0625rem;
      color: #fff;
      text-align: center;
      border-radius: 0.25rem;
      width: 94%;
      height: 3rem;
    }
  }
}
</style>

